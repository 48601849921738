<template>
  <div class="lfont">
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <h4>
          {{ $t("employee_roster.request_change_work_sheet.date") }}:
          <span class="text-primary">{{ date }}</span>
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <h4>
          {{ $t("employee_roster.request_change_work_sheet.from_sheet") }}:
          <span class="text-primary"
            >{{ schedule_detail_name }} ({{ start_in }} - {{ start_out }})</span
          >
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4" md="4">
        <div class="input-group">
          <h4>
            {{ $t("employee_roster.request_change_work_sheet.to_sheet") }}:
          </h4>
          <v-select
            :items="workSheet"
            solo
            dense
            item-value="schedule_detail_id"
            item-text="sheet_name"
            v-model="select_chedule_detail_id"
            @change="fetchEmployee"
          ></v-select>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4" md="4">
        <div class="input-group">
          <h4>
            {{ $t("employee_roster.request_change_work_sheet.swich_with") }}:
          </h4>
          <v-select
            :items="employee"
            solo
            dense
            item-value="emp_id"
            item-text="full_name"
            v-model="to_employee_id"
          ></v-select>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <v-btn class="ma-2" color="info btn-request" @click="submitSaveRequest">
          Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schedule_detail_id: this.$route.params.schedule_detail_id,
      schedule_detail_name: this.$route.params.schedule_detail_name,
      start_in: this.$route.params.start_in,
      start_out: this.$route.params.start_out,
      date: this.$route.params.date,
      workSheet: [],
      employee: [],
      select_chedule_detail_id: "",
      to_employee_id: "",
      server_errors: {
        date: "",
        from_schedule_detail_id: "",
        to_employee_id: "",
        to_schedule_detail_id: "",
      },
    };
  },
  methods: {
    fetchEmployee() {
      this.$axios
        .get(`employee/roster/worksheet/employee`, {
          params: {
            date: this.date,
            schedule_detail_id: this.select_chedule_detail_id,
          },
        })
        .then((res) => {
          this.employee = res.data.data;
          this.isLoading = false;
        })
        .catch(() => {});
    },
    fetchWorkSheet() {
      this.$axios
        .get(`employee/roster/worksheet/list`, {
          params: {
            date: this.date,
            schedule_detail_id: this.schedule_detail_id,
          },
        })
        .then((res) => {
          this.workSheet = res.data.data;
          this.isLoading = false;
        })
        .catch(() => {});
    },
    checkRefresh() {
      if (!this.schedule_detail_id) {
        this.$router
          .push({
            name: "employee_roster.index",
          })
          .catch(() => {});
      }
    },
    submitSaveRequest() {
      let items = {
        date: this.date,
        from_schedule_detail_id: this.schedule_detail_id,
        to_employee_id: this.to_employee_id,
        to_schedule_detail_id: this.select_chedule_detail_id,
      };
      this.$axios
        .post(`employee/roster/request/change/worksheet`, items)
        .then((res) => {
          if (res.status === 200) {
            this.$router
              .push({
                name: "employee_roster.index",
              })
              .catch(() => {});
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  created() {
    this.checkRefresh();
    this.fetchWorkSheet();
  },
};
</script>

<style scoped lang="scss">
.input-group {
  display: flex;
  h4 {
    padding-top: 5px;
    margin-right: 3px;
  }
}
.btn-request {
  color: white !important;
  width: 100%;
}
</style>